import React from "react";
import styled from "styled-components";
import { hoverChange } from "../../functions/util";
import Box from "../motion/motion-box";
import ReactPlayer from "react-player";
import VideoCover from "../inline-video/index";
import MainModal from "../globals/modal";

const FeaturedWrapper = styled(Box)`
  display: flex;
  position: relative;
  overflow: hidden;

  .gatsby-image-wrapper {
    border-radius: 0;
  }
`;

const VideoWrapper = styled(Box)`
  min-height: 50vw;
  width: 100vw;
  position: relative;

  @media (hover: none) {
    position: relative;

    &:hover {
      &:after {
        transform: scale(1.1) translateY(-50%) translateX(-50%) rotate(0deg);
      }
    }

    &:active {
      &:after {
        transform: scale(0.9) translateY(-50%) translateX(-50%) rotate(0deg);
      }
    }

    &:after {
      content: "";
      width: 8rem;
      height: 8rem;
      opacity: 1;
      background: url("/svg/play.svg") no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(0deg);
      transform-origin: 0% 0%;
      transition: transform
          ${(props) => props.theme.animation.duration[300].css}
          ${(props) => props.theme.animation.timingFunction.css},
        opacity ${(props) => props.theme.animation.duration[100].css}
          ${(props) => props.theme.animation.timingFunction.css} !important;
    }
  }
`;

function VideoPlayerSection({ data }) {
  const [modalVisible, handleModal] = React.useState(false);

  const videoOptions = {
    autoPlay: true,
    playsInline: true,
    preload: "auto",
    muted: true,
    loop: true,
  };

  const style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    background: "black",
  };

  const backgroundSource = [
    { src: data.primary.background_video_override.url, type: "video/mp4" },
  ];

  const mainSource = [{ src: data.primary.main_video.url, type: "video/mp4" }];

  return (
    <>
      {mainSource && (
        <FeaturedWrapper tabIndex={0}>
          <VideoWrapper
            onClick={(e) => handleModal(true)}
            onMouseEnter={() => hoverChange("video")}
            onMouseLeave={() => hoverChange(null)}
          >
            <VideoCover
              videoOptions={videoOptions}
              source={
                backgroundSource[0].src === null ? mainSource : backgroundSource
              }
              remeasureOnWindowResize
            />
          </VideoWrapper>
          <MainModal modalVisible={modalVisible} handleModal={handleModal}>
            <ReactPlayer
              id={"videoFull"}
              style={style}
              width="100%"
              height="100%"
              loop
              playing
              controls
              muted={false}
              volume={0.2}
              url={mainSource}
            />
          </MainModal>
        </FeaturedWrapper>
      )}
    </>
  );
}

export default VideoPlayerSection;
