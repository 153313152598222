import React from "react";
import MotionHeadline from "./motion/motion-headline";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import WorkTile from "./work-tile";
import ColumnWrapper from "./globals/column";
import Link from "./helpers/link";

const Container = styled(Wrapper)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
`;

const WorkWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  padding-top: 2.25rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    padding-top: 4vw;
  }

  .work-link,
  a {
    /* display: inline-table; */
    display: inline-block;
    width: 100%;
  }
`;

const NullTile = styled.div`
  height: 25rem;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    height: 30vw;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    width: auto;

    &:hover p {
      transform: translate3d(0, -0.5rem, 0);
    }
  }

  p {
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    z-index: 10;
    pointer-events: none;
  }
`;

function WorkPagination({ pageData }) {
  if (!pageData?.previous && !pageData?.next) return <></>;

  return (
    <Container>
      <ColumnWrapper start={2} number={12}>
        <MotionHeadline text="More Work" className="h1 more-headline" />
        <WorkWrapper>
          {pageData?.previous ? (
            <WorkTile
              idx={1}
              node={{
                uid: pageData?.previous.uid,
                tags: pageData?.previous.tags,
                data: {
                  title: `Prev: ${pageData?.previous.data.title}`,
                  marquee_image: pageData?.previous.data.marquee_image,
                },
              }}
            />
          ) : (
            <NullTile>
              <Link to="/work">
                <MotionHeadline text="View All Work" className="h5" />
              </Link>
            </NullTile>
          )}
          {pageData?.next ? (
            <WorkTile
              idx={1}
              node={{
                uid: pageData?.next.uid,
                tags: pageData?.next.tags,
                data: {
                  title: `Next: ${pageData?.next.data.title}`,
                  marquee_image: pageData?.next.data.marquee_image,
                },
              }}
            />
          ) : (
            <NullTile>
              <Link to="/work">
                <MotionHeadline text="View All Work" className="h5" />
              </Link>
            </NullTile>
          )}
        </WorkWrapper>
      </ColumnWrapper>
    </Container>
  );
}

export default WorkPagination;
