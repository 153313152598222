import React from "react";
import FullSection from "./full-section";
import SplitSection from "./split-section";
import ContentSection from "./content-section";
import VideoPlayerSection from "./video-player-section";
import GallerySection from "./gallery-section";
import GalleryGridSection from "./gallery-grid-section";
import styled from "styled-components";

const Grid = styled.div`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

export default function WorkSlices({ body }) {
  const sliceComponents = {
    full_section: FullSection,
    split_section: SplitSection,
    call_to_action: ContentSection,
    video_player: VideoPlayerSection,
    gallery: GallerySection,
    gallery_grid: GalleryGridSection,
  };

  return (
    <Grid tabIndex={0}>
      {body.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          return <SliceComponent key={`slice-${index}`} data={slice} />;
        }
        return <pre key={index}>{slice.slice_type}</pre>;
      })}
    </Grid>
  );
}
