import React from "react";
import styled from "styled-components";
import { Caption } from "./full-section";
import MediaLoader from "../media-loader";

export const Wrapper = styled.section`
  --pad: calc(${({ theme }) => theme.layout.outerWrapper} * 2);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.25rem;
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  @media ${({ theme }) => theme.device.tablet} {
    min-height: 50vw;
    grid-template-columns: 1fr 1fr;
  }

  // Left
  &[data-large-image="false"] {
    --left-padding: 0;
    --right-padding: var(--pad) var(--pad) calc(var(--pad) * 2) var(--pad);
    --left-border-radius: 0 0.25rem 0.25rem 0;
    --right-border-radius: 0.25rem;

    @media ${({ theme }) => theme.device.tablet} {
      --right-padding: var(--pad);
    }
  }

  // Right
  &[data-large-image="true"] {
    --left-padding: var(--pad) var(--pad) calc(var(--pad) * 2) var(--pad);
    --right-padding: 0;
    --left-border-radius: 0.25rem;
    --right-border-radius: 0.25rem 0 0 0.25rem;

    @media ${({ theme }) => theme.device.tablet} {
      --left-padding: var(--pad);
    }
  }
`;

const SplitSide = styled.div`
  position: relative;
  display: inline;
  width: 100%;
  height: 100%;
  min-height: 80vw;
  overflow: hidden;

  @media ${({ theme }) => theme.device.tablet} {
    min-height: unset;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  &.split-left {
    padding: var(--left-padding);

    .gatsby-image-wrapper {
      border-radius: var(--left-border-radius);
    }
  }
  &.split-right {
    padding: var(--right-padding);

    .gatsby-image-wrapper {
      border-radius: var(--right-border-radius);
    }
  }
`;

const CaptionBox = styled(Caption)`
  --space: ${({ theme }) => theme.layout.outerWrapperMobile};
  --space-bottom-with-inset: calc(var(--space) * 0.8);
  --space-left-with-inset: calc(
    ${({ theme }) => theme.layout.outerWrapperMobile} * 2
  );
  position: absolute;
  color: ${({ theme }) => theme.colors.black};
  bottom: var(--space);
  left: var(--space);
  z-index: 2;

  @media ${({ theme }) => theme.device.tablet} {
    --space: ${({ theme }) => theme.layout.outerWrapperMobile};
    --space-bottom-with-inset: calc(
      ${({ theme }) => theme.layout.outerWrapper} * 1.35
    );
    --space-left-with-inset: calc(
      ${({ theme }) => theme.layout.outerWrapper} * 2
    );
  }

  &[data-inset="true"] {
    left: var(--space-left-with-inset);
    bottom: var(--space-bottom-with-inset);
  }
`;

function SplitSection({ data }) {
  const {
    large_image,
    left_figure_caption,
    left_figure_image,
    left_figure_video_override,
    right_figure_caption,
    right_figure_image,
  } = data.primary;
  return (
    <Wrapper data-large-image={large_image} tabIndex={0}>
      <SplitSide className="split-left">
        <MediaLoader
          video={left_figure_video_override.url}
          videoProps={{
            className: "image",
            hiddenStyles: {
              y: 0,
            },
          }}
          image={left_figure_image.fluid}
          imageProps={{
            className: "image",
            hiddenStyles: {
              y: 0,
            },
          }}
        />
        <CaptionBox data-inset={large_image === true}>
          {left_figure_caption.text}
        </CaptionBox>
      </SplitSide>
      <SplitSide className="split-right">
        <MediaLoader
          video={left_figure_video_override.url}
          videoProps={{
            className: "image",
            hiddenStyles: {
              y: 0,
            },
          }}
          image={right_figure_image.fluid}
          imageProps={{
            className: "image",
            hiddenStyles: {
              y: 0,
            },
          }}
        />
        <CaptionBox data-inset={large_image === false}>
          {right_figure_caption.text}
        </CaptionBox>
      </SplitSide>
    </Wrapper>
  );
}

export default SplitSection;
