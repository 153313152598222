import React from "react";
import { graphql } from "gatsby";

import WorkSlices from "../../components/work-sections/index";
import WorkMarquee from "../../components/work-marquee";
import { themeChange } from "../../functions/util";
import WorkPagination from "../../components/work-pagination";
import { Helmet } from "react-helmet";
import useSiteMeta from "../../hooks/use-site-meta";

function onlyUnique(value, index, self) {
  if (value === null) {
    return null;
  } else return self.indexOf(value) === index;
}

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Project({
  data: {
    allPrismicProject: { edges: node },
    prismicProject: { data, uid },
  },
}) {
  const {
    data: { site_url },
  } = useSiteMeta();

  React.useEffect(() => {
    themeChange("light");
    return () => themeChange(null);
  });

  function getPostPrevNext(o) {
    const val = o.map((i, ix) => {
      if (i.node.uid === uid) {
        return ix;
      } else return null;
    });
    const index = val.filter(onlyUnique).shift();
    return node[index];
  }

  const pageData = getPostPrevNext(node);

  return (
    <>
      <Helmet
        title={`Cramdyn — ${data.title}`}
        meta={[
          // Basics
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1, shrink-to-fit=yes",
          },
          // Open Graph / Facebook
          { property: "og:type", content: "website" },
          { property: "og:url", content: `${site_url}/${uid}` },
          {
            property: "og:description",
            content: `${data.title}`,
          },
          { property: "og:image", content: data.marquee_image.url },
          // Twitter
          { property: "twitter:card", content: "summary_large_image" },
          { property: "twitter:url", content: `${site_url}/${uid}` },
          {
            property: "twitter:description",
            content: `${data.title}`,
          },
          { property: "twitter:image", content: data.marquee_image.url },
        ]}
      />
      <WorkMarquee data={data} />
      <WorkSlices body={data.body} />
      <WorkPagination pageData={pageData} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicProject($uid: String!) {
    allPrismicProject(
      filter: { data: { page_visibility: { ne: false } } }
      sort: { fields: data___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          uid
          prismicId
        }
        next {
          id
          uid
          type
          tags
          data {
            title
            marquee_image {
              fluid(maxWidth: 1800) {
                ...GatsbyPrismicImageFluid
                aspectRatio
              }
            }
          }
        }
        previous {
          id
          uid
          type
          tags
          data {
            title
            marquee_image {
              fluid(maxWidth: 1800) {
                ...GatsbyPrismicImageFluid
                aspectRatio
              }
            }
          }
        }
      }
    }
    prismicProject(uid: { eq: $uid }) {
      uid
      id
      data {
        headline {
          text
          raw
          html
        }
        project_hex
        brief_title
        credits_title
        date
        title
        brief_text {
          text
          raw
          html
        }
        credits_text {
          text
          raw
          html
        }
        marquee_video_override {
          url
        }
        marquee_image {
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
          url
        }
        date
        body {
          ... on PrismicProjectDataBodyFullSection {
            id
            slice_type
            primary {
              caption_position
              inset
              figure_video_override {
                url
              }
              figure_image {
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
              figure_caption {
                text
              }
            }
          }
          ... on PrismicProjectDataBodySplitSection {
            id
            slice_type
            primary {
              large_image
              left_figure_caption {
                text
              }
              left_figure_image {
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
              left_figure_video_override {
                url
              }
              right_figure_caption {
                text
              }
              right_figure_image {
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
              right_figure_video_override {
                url
              }
            }
          }
          ... on PrismicProjectDataBodyCallToAction {
            id
            slice_type
            primary {
              title {
                text
              }
              paragraph {
                raw
                html
              }
              eyebrow {
                text
              }
            }
          }
          ... on PrismicProjectDataBodyGallery {
            id
            slice_type
            items {
              gallery_video_override {
                id
                url
              }
              gallery_image {
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
              gallery_caption {
                text
                html
                raw
              }
            }
          }
          ... on PrismicProjectDataBodyGalleryGrid {
            id
            slice_type
            items {
              gallery_caption {
                text
              }
              gallery_image {
                fluid(maxWidth: 1080) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
              gallery_video_override {
                url
              }
            }
          }
          ... on PrismicProjectDataBodyVideoPlayer {
            id
            slice_type
            primary {
              main_video {
                url
              }
              background_video_override {
                url
              }
              figure_caption {
                text
              }
            }
          }
        }
      }
    }
  }
`;
