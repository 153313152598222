import React from "react";
import MotionHeadline from "../motion/motion-headline";
import RichTextHelper from "../helpers/rich-text";
import styled from "styled-components";
import { Wrapper as Wrap } from "../globals/wrappers";
import Box from "../motion/motion-box";

const Wrapper = styled(Wrap)`
  position: relative;
  display: inline-flex;
  margin-top: 8vw;
  margin-bottom: 8vw;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const ContentWrap = styled(Box)`
  max-width: 28rem;

  .eyebrow {
    margin-bottom: 4vw;
  }

  .title {
    margin-bottom: 0.3em;
  }
`;

function ContentSection({ data }) {
  return (
    <Wrapper tabIndex={0}>
      <ContentWrap>
        <MotionHeadline
          className="h5 eyebrow"
          text={data.primary.eyebrow.text}
        />
        <MotionHeadline className="h3 title" text={data.primary.title.text} />
        <RichTextHelper render={data.primary.paragraph.raw} />
      </ContentWrap>
    </Wrapper>
  );
}

export default ContentSection;
