import React from "react";
import ImageBox from "./motion/motion-box/image-box";
import Link from "./helpers/link";
import { domAnimation, LazyMotion, m } from "framer-motion";
import Box from "./motion/motion-box";
import { hoverChange, hoverCustomProperty } from "../functions/util";
import styled from "styled-components";

const WorkBox = styled(Box)`
  position: relative;
  height: 25rem;

  @media ${({ theme }) => theme.device.tablet} {
    height: 30vw;
  }

  .work-box-image,
  .gatsby-image-wrapper {
    height: 100%;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  p {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.peach};
  }

  h6,
  .h6 {
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
`;

const WorkBoxContent = styled.div`
  display: none;

  @media (hover: none) {
    display: inline-block;
    margin: 1rem 0;
  }
`;

const WorkBoxContainer = styled(m.div)`
  &:hover {
    .gatsby-image-wrapper {
      transform: translate3d(0, -0.5rem, 0);
    }
  }
`;

function WorkTile({
  idx,
  node: {
    uid,
    tags,
    data: { title, marquee_image },
  },
}) {
  return (
    <LazyMotion features={domAnimation}>
      <WorkBoxContainer
        layout
        className="work-link"
        layoutId={idx}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Link
          to={`/work/${uid}`}
          data-tags={tags}
          onMouseEnter={() => {
            hoverChange("view-project-specific");
            hoverCustomProperty("custom-content", title);
          }}
          onMouseLeave={() => {
            hoverChange(null);
            hoverCustomProperty(null);
          }}
        >
          <WorkBox>
            <ImageBox className="work-box-image" fluid={marquee_image.fluid} />
            <WorkBoxContent>
              <p className="h6">{title}</p>
              <p className="p-small">View Project</p>
            </WorkBoxContent>
          </WorkBox>
        </Link>
      </WorkBoxContainer>
    </LazyMotion>
  );
}

export default WorkTile;
