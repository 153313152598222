import React from "react";
import styled from "styled-components";
import { Wrapper as Wrap } from "../globals/wrappers";
import MediaLoader from "../media-loader";
import { Caption } from "./full-section";

const Wrapper = styled(Wrap)`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const GalleryItem = styled.div`
  .grid-image {
    width: 100%;
    height: 40vw;
  }
`;

const CaptionWrap = styled.div`
  /* display: inline; */
  margin: 1rem 0;
`;

function GalleryGridSection({ data: { items } }) {
  return (
    <Wrapper tabIndex={0}>
      {items.map(
        ({ gallery_caption, gallery_image, gallery_video_override }, i) => {
          return (
            <GalleryItem key={i}>
              <MediaLoader
                video={gallery_video_override.url}
                videoProps={{
                  className: "grid-image",
                  hiddenStyles: {
                    y: 0,
                  },
                }}
                image={gallery_image.fluid}
                imageProps={{
                  className: "grid-image",
                  hiddenStyles: {
                    y: 0,
                  },
                }}
              />
              <CaptionWrap>
                <Caption>{gallery_caption.text}</Caption>
              </CaptionWrap>
            </GalleryItem>
          );
        }
      )}
    </Wrapper>
  );
}

export default GalleryGridSection;
