import React from "react";
import Box from ".";
import VideoCover from "../../inline-video";
import { useInView } from "react-intersection-observer";

export default function VideoBox({ video, ...rest }) {
  const { ref, inView } = useInView({ triggerOnce: false });

  const videoOptions = {
    autoPlay: true,
    playsInline: true,
    preload: "auto",
    muted: true,
    loop: true,
  };

  return (
    <Box {...rest}>
      <div ref={ref}>
        {inView && (
          <VideoCover
            videoOptions={videoOptions}
            source={[{ src: video.url, type: "video/mp4" }]}
            remeasureOnWindowResize
          />
        )}
      </div>
    </Box>
  );
}
