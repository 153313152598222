import React from "react";
import ImageBox from "./motion/motion-box/image-box";
import VideoBox from "./motion/motion-box/video-box";

export default function MediaLoader({ video, videoProps, image, imageProps }) {
  return (
    <>
      {video && (
        <VideoBox
          video={{
            url: video,
          }}
          {...videoProps}
        />
      )}
      {image && !video && <ImageBox fluid={image} {...imageProps} />}
    </>
  );
}
