import React from "react";
import styled from "styled-components";
import MediaLoader from "../media-loader";

export const Wrapper = styled.section`
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  .gatsby-image-wrapper {
    border-radius: unset;
  }

  &[data-inset="true"] {
    padding: 0 ${({ theme }) => theme.layout.outerWrapperMobile};
    width: 100%;

    .gatsby-image-wrapper {
      border-radius: 0.25rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      padding: 0 ${({ theme }) => theme.layout.outerWrapper};
    }
  }
`;

export const Caption = styled.p`
  font-size: ${({ theme }) => theme.fonts.paragraph};
  line-height: 1.25;
  margin-bottom: 0;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fonts.paragraphLarge};
  }
`;

const CaptionBox = styled(Caption)`
  --space: ${({ theme }) => theme.layout.outerWrapperMobile};
  --space-with-inset: calc(var(--space) * 2);
  position: absolute;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fonts.paragraph};
  bottom: var(--space);

  @media ${({ theme }) => theme.device.tablet} {
    --space: ${({ theme }) => theme.layout.outerWrapper};
    --space-with-inset: calc(var(--space) * 2);
    font-size: ${({ theme }) => theme.fonts.paragraphLarge};
  }

  // Left
  &[data-caption-position="false"] {
    left: var(--space);

    &[data-inset="true"] {
      left: var(--space-with-inset);
    }
  }

  // Right
  &[data-caption-position="true"] {
    right: var(--space);

    &[data-inset="true"] {
      right: var(--space-with-inset);
    }
  }
`;

function FullSection({ data }) {
  const {
    caption_position,
    inset,
    figure_caption,
    figure_image,
    figure_video_override,
  } = data.primary;
  return (
    <Wrapper data-inset={inset} tabIndex={0}>
      <MediaLoader
        video={figure_video_override.url}
        videoProps={{
          className: "image",
          hiddenStyles: {
            y: 0,
          },
        }}
        image={figure_image.fluid}
        imageProps={{
          className: "image",
          hiddenStyles: {
            y: 0,
          },
        }}
      />
      <CaptionBox data-inset={inset} data-caption-position={caption_position}>
        {figure_caption.text}
      </CaptionBox>
    </Wrapper>
  );
}

export default FullSection;
