import React from "react";
import MotionHeadline from "./motion/motion-headline";
import RichTextHelper from "./helpers/rich-text";
import MediaLoader from "./media-loader";
import { AnimateHeight } from "./animate-height";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import Box from "./motion/motion-box";

const Container = styled.section`
  --main-background: ${({ projectHex }) => (projectHex ? projectHex : "white")};
  color: var(--main-foreground);
  background: var(--main-background);
`;

const WorkMarqueeWrapper = styled(Wrapper)`
  padding-top: calc(6rem + 8vw);
  padding-bottom: 6vw;

  .page-title {
    opacity: 0.3;
  }

  .project-headline {
    max-width: 8em;
  }
`;

const WorkMarqueeImage = styled.div`
  background: var(--main-background);

  .gatsby-image-wrapper {
    border-radius: 0;
  }
`;

const MarqueeRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const DetailsButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  color: inherit;
  background: transparent;
  border: none;
  padding: 0;

  p {
    margin: 0;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      color ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css} !important;
  }

  &:hover p {
    transform: translateY(-0.1em);
  }

  &:active p {
    transform: translateY(0.1em);
  }
`;

const DetailsRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  margin: 6vw auto 0;

  .section-title {
    margin-bottom: 2rem;
  }

  .section-body {
    padding-left: ${({ theme }) => theme.layout.outerWrapper};
  }
`;

function WorkMarquee({ data }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openText, setOpenText] = React.useState("Details +");

  const toggleOpen = () => {
    setIsOpen(!isOpen);

    if (openText.includes("+")) {
      setOpenText("Details -");
    } else {
      setOpenText("Details +");
    }
  };

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
      x: 0,
    },
    collapsed: { opacity: 0, height: 0, x: 0 },
  };

  return (
    <Container projectHex={data?.project_hex}>
      <WorkMarqueeWrapper>
        <MarqueeRow>
          <div>
            <MotionHeadline text="Project" className="h2 serif page-title" />
            <MotionHeadline
              text={data?.headline?.text}
              delay={0.25}
              className="h2 sans project-headline"
            />
          </div>
          <DetailsButton onClick={toggleOpen}>
            <MotionHeadline text={openText} delay={0.5} className="h3 sans" />
          </DetailsButton>
        </MarqueeRow>
        <AnimateHeight variants={variants} isVisible={isOpen}>
          <DetailsRow>
            <Box>
              <MotionHeadline
                text={data?.brief_title}
                className="h6 section-title"
              />
              <MotionHeadline
                text={data?.brief_text?.text}
                className="h4 serif section-body"
              />
            </Box>
            <Box>
              <MotionHeadline
                text={data?.credits_title}
                className="h6 section-title"
              />
              <div className="section-body">
                <RichTextHelper render={data?.credits_text?.raw} />
              </div>
            </Box>
          </DetailsRow>
        </AnimateHeight>
      </WorkMarqueeWrapper>
      <WorkMarqueeImage>
        <MediaLoader
          video={data?.marquee_video_override?.src}
          videoProps={{
            className: "marquee-image",
            hiddenStyles: {
              y: 0,
            },
            transitionOverrides: {
              delay: 0.75,
            },
          }}
          image={data?.marquee_image?.fluid}
          imageProps={{
            className: "marquee-image",
            hiddenStyles: {
              y: 0,
            },
            transitionOverrides: {
              delay: 0.75,
            },
          }}
        />
      </WorkMarqueeImage>
    </Container>
  );
}

export default WorkMarquee;
