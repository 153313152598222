import React from "react";
import { m, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import styled from "styled-components";
import Box from "../motion/motion-box";
import SvgLoader from "../svg";
import { animation } from "../../style/theme";
import { hoverChange } from "../../functions/util";
import MediaLoader from "../media-loader";
import { Caption } from "./full-section";

const FeaturedWrapper = styled(Box)`
  height: 45vw;
  min-height: 23rem;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  .gatsby-image-wrapper {
    border-radius: 0;
  }
`;

const FeaturedInner = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  a,
  .marquee-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Controls = styled(m.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.layout.outerWrapperMobile};
  color: ${({ theme }) => theme.colors.black};

  a {
    color: ${({ theme }) => theme.colors.black};
  }

  svg path {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.layout.outerWrapper};

    svg path {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const ControlsRow = styled(m.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  * {
    user-select: none;
  }
`;

const ControlsGroup = styled(m.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  transform: translateX(0.55rem);
`;

const ControlButton = styled(m.div)`
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  svg {
    opacity: 1;
    transition: opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  &:hover {
    transform: translateY(-0.1em);

    svg {
      opacity: 0;
    }
  }
`;

const variants = {
  enter: {
    scale: 1.25,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    scale: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    scale: 0.75,
    opacity: 0,
  },
};

function Gallery({ items }) {
  const [[page, direction], setPage] = React.useState([0, 0]);
  const docIndex = wrap(0, items.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      paginate(1);
    }, 10000);

    return () => clearInterval(interval);
  });

  const image = items[docIndex].gallery_image.fluid;
  const caption = items[docIndex].gallery_caption.text;
  const video = items[docIndex].gallery_video_override.url;

  return (
    <FeaturedWrapper tabIndex={0}>
      <AnimatePresence initial={false} custom={direction}>
        <FeaturedInner
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            scale: {
              ease: animation.timingFunction.js,
              duration: animation.duration[300].js,
            },
            opacity: {
              ease: animation.timingFunction.js,
              duration: animation.duration[200].js,
            },
          }}
        >
          <MediaLoader
            video={video}
            videoProps={{
              className: "marquee-image",
              hiddenStyles: {
                y: 0,
              },
            }}
            image={image}
            imageProps={{
              className: "marquee-image",
              hiddenStyles: {
                y: 0,
              },
            }}
          />
        </FeaturedInner>
      </AnimatePresence>
      <Controls>
        <ControlsRow>
          <ControlsGroup>
            <ControlButton
              className="prev"
              onMouseEnter={() => hoverChange("paginate-prev")}
              onMouseLeave={() => hoverChange(null)}
              onClick={() => paginate(-1)}
            >
              <SvgLoader svg="CaretLeft" color="green" />
            </ControlButton>
            <ControlButton
              className="next"
              onMouseEnter={() => hoverChange("paginate-next")}
              onMouseLeave={() => hoverChange(null)}
              onClick={() => paginate(1)}
            >
              <SvgLoader svg="CaretRight" color="green" />
            </ControlButton>
          </ControlsGroup>
        </ControlsRow>
        <ControlsRow>
          <Caption>{caption}</Caption>
        </ControlsRow>
      </Controls>
    </FeaturedWrapper>
  );
}

export const Wrapper = styled.section`
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

function GallerySection({ data }) {
  return (
    <Wrapper>
      <Gallery items={data.items} />
    </Wrapper>
  );
}

export default GallerySection;
